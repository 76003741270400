import { useEffect, useState } from 'react';

export function useWindowSize({ scroll }: { scroll?: boolean } = {}) {
  const isClient = typeof window === 'object';
  function getSize() {
    if (!isClient)
      return {
        height: undefined,
        width: undefined,
      };

    if (scroll) {
      return {
        width: document.body.scrollWidth,
        height: document.body.scrollHeight,
      };
    }
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return () => false;
    }
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
