import { keyframes } from '@emotion/core';

export const fadeInAnimation = keyframes`
from {
   opacity: 0;
}
to {
   opacity: 1;
}
`;

export const scaleUpAnimation = keyframes`
from {
   opacity: 0;
   transform: scale(0) rotate(45deg);
}
to {
   opacity: 1;
   transform: scale(1) rotate(45deg);
}
`;

export const enterUpAnimation = keyframes`
from {
   opacity: 0;
   transform: translateY(12px);
}
to {
   opacity: 1;
   transform: translateY(0);
}
`;

export const checkmarkAnimation = keyframes`
  0% {
		height: 0px;
		width: 0px;
		opacity: 0;
		visibility: visible;
  }

  40% {
		height: 0px;
		width: 10px;
		opacity: 1;
  }

  100% {
	visibility: visible;
		height: 20px;
  }
`;
